<template>
  <div id="PlatformIntroduction" class="wrap">
    <van-nav-bar
      :fixed="true"
      placeholder
      :title="$t('My.PlatformIntroduction.PlatformIntroduction')"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="content">
      <div class="Text">
        <!-- <p>{{$t('My.PlatformIntroduction.One')}}</p>
                <br> -->
        <p>{{ $t('My.PlatformIntroduction.Two') }}</p>
        <br />
        <p>{{ $t('My.PlatformIntroduction.Three') }}</p>
        <br />
        <p>{{ $t('My.PlatformIntroduction.Four') }}</p>
        <br />
        <p>{{ $t('My.PlatformIntroduction.Five') }}</p>
        <br />
        <!-- <van-image  width="100%"  :src="require('@/assets/Img/myImg/PengenalanPlatform.jpg')" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Image,
} from 'vant'

export default {
  name: 'PlatformIntroduction',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
  },
  data() {
    return {}
  },
  methods: {
    // 返回上一个路由
    onClickLeft() {
      this.$router.go(-1)
    },
  },
  created() {
    window.scroll(0, 0)
  },
}
</script>

<style lang="less">
#PlatformIntroduction {
  width: 100%;
  height: 100%;
  .content {
    width: calc(100% - 1.25rem);
    padding: 0.9375rem 0.625rem;
    background-color: #f2f2f2;
    > div {
      width: calc(100% - 1.25rem);
      padding: 1.25rem 0.625rem;
      border-radius: 0.625rem;
      background-color: #fff;
      font-size: 13px;
      color: #333;
    }
  }
}
</style>